import {
    isArray,
    length,
    isEmptyArray,
    contains,
    equalLength,
    equalContents,
    indexOfItem,
    indexOfObject
} from './queries_arrays';
import { filter, remove, removeAll } from './deletions_arrays';
import { concat, concatMap, prepend } from './merges_arrays';
import { sumLengths } from './reductions_arrays';
import { sortWith, sortByPath, sortByProp, alphabetize } from './sorts_arrays';
import { slice, at, take, takeAfter, head, tail } from './subsets_arrays';
import { asArray, fmap, flatten, toggle } from './transforms_arrays';

/**
    Table of content types:
        Legend:
            :: translates to "has type" (e.g.,  5 :: Integer)
            Lowercase letters are generic or unknown types
            -> roughly means "returns the following type"


    alphabetize :: [String] -> [String]
        - Lexical sort of array

    asArray :: a -> [a]
    asArray :: [a] -> [a]
        - Wrap value in array
        - If already array, do nothing

    at :: Integer -> [a] -> a
        - Return value at index

    concat :: ([a], b) -> [a, b]
        - Append b to [a] 
        - b will not be nested if an array

    concatMap :: (a -> b) -> [a] -> [b]
        - Runs f on each element in array, concatenating results

    contains :: ([a], b) -> Boolean
        - Is b in [a]

    equalLength :: ([a], [b]) -> Boolean
        - length(a) === length(b)

    equalContents :: ([a], [b]) -> Boolean
        - Is each element of a at every index the same as each element of b at the same index
        - Will not work for nested objects in arrays

    filter :: (a -> Boolean) -> [a] -> [a]
        - Keep values where predicate returns true

    flatten :: [[a]] -> [a]
        - Remove a level of nesting

    head :: [a] -> [a]
        - Return first element in array

    isArray :: a -> Boolean
        - is a an array

    isEmptyArray :: [a] -> Boolean
        - length of array is zero

    indexOfItem :: ([a], value) -> Ingeger
        - index of element on array

    indexOfObject :: ([a], {k:j}, k) -> Integer
        - find an object inside array using the given key
        - return index 

    length :: [a] -> Integer
        - number of elements in array

    fmap :: (a -> b) -> [a] -> [b]
        - Run transformation on [a]

    prepend :: a -> [b] -> [a, b]
        - Insert a as first elements in [b]

    remove :: ([a], a, Integer) -> [a]
        - Remove a or Integer index from [a]

    removeAll :: [a] -> [a] -> [a]
        - Remove all elements in first array from second array

    slice :: Integer -> Integer -> [a] -> [a]
        - Return subset of array between two indices

    sortByPath :: (String, Comparator) -> ([Object], Boolean) -> [a]
        - Sort by prop of Object using Comparator
        - Comparator :: ((a, a, Boolean) -> Integer)

    sortWith :: Comparator -> [a] -> [a]
        - Sort [a] using Comparator
        - Comparator :: ((a, a, Boolean) -> Integer)

    sumLengths :: [[a]] -> Integer
        - Sum lengths of all nested [a]

    tail :: [a] -> [a]
        - Return last element in [a]

    take :: Integer -> [a] -> [a]
        - Returns the first n elements of [a]

    takeAfter :: Integer -> [a] -> [a]
        - Returns elements of [a] after Integer index

    toggle :: ([a], b) -> [a]
        - If b is in [a], remove b
        - If b is not in [a], concat b
*/

export {
    alphabetize,
    asArray,
    at,
    concat,
    concatMap,
    contains,
    equalLength,
    equalContents,
    filter,
    flatten,
    head,
    indexOfItem,
    indexOfObject,
    isArray,
    isEmptyArray,
    length,
    fmap,
    prepend,
    remove,
    removeAll,
    slice,
    sortByPath,
    sortByProp,
    sortWith,
    sumLengths,
    tail,
    take,
    takeAfter,
    toggle
};
